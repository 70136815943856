@tailwind base;
@tailwind components;
@tailwind utilities;

.terms {
  @apply p-8;
}
.terms h1 {
  @apply text-3xl;
  @apply mb-4;
}
.terms h2 {
  @apply text-2xl; 
  @apply m-4;
}

.privacy {
  @apply p-8;
}
.privacy h1 {
  @apply text-3xl;
  @apply mb-4;
}
.privacy h2 {
  @apply text-2xl; 
  @apply m-4;
}
